<template>
  <div v-if="!url || isDefaultUrlName" class="fileUpload-set">
    <label :class="{ 'is-error': (isError | fileError) && !isAudioType, 'fileUpload-label': !isAudioType }">
      <input
        ref="uploadedFile"
        :class="{ 'fileUpload-input': true, 'form-audio-input': isAudioType }"
        type="file"
        :accept="acceptedExtensions"
        @change="upload"
        :disabled="disabled"
      />
      <button
        v-if="isAudioType"
        class="btn btn-black limit-text"
        :class=" { 'is-error': (isError | fileError) && isAudioType }"
        type="button"
        @click="uploadAudio"
      >
        {{ newUrlName }}
      </button>
      <span class="fileUpload-btn" v-else></span>
    </label>
  </div>
  <div v-else class="fileUpload-set is-upload">
    <label :class="{ 'fileUpload-label': !isAudioType }">
      <input
        ref="uploadedFile"
        :class="{ 'fileUpload-input': true, 'form-audio-input': isAudioType }"
        type="file"
        :accept="acceptedAllExtensions"
        @change="upload"
        :disabled="disabled"
      />
      <button
        v-if="isAudioType"
        class="btn btn-black limit-text"
        :class=" { 'is-error': (isError | fileError) && isAudioType }"
        type="button"
        @click="uploadAudio"
      >
        {{ newUrlName }}
      </button>
      <span class="fileUpload-btn"></span>
      <img class="fileUpload-thumb" :src="url" v-if="!isAudioType"/>
      <div v-if="loading" class="loading-upload">
        <vue-loading class="loading-spinner" type="spin" :size="{ width: '25px', height: '25px' }"></vue-loading>
      </div>
    </label>
    <button v-if="isBtnDeleteShown" :disabled="loading" class="fileUpload-delete" type="button" @click="deleteImage">
      <i class="aikon aikon-cross"></i>
    </button>
  </div>
</template>

<script>
//https://qiita.com/itoshiki/items/511d58b827f4ce2129fc
import { mapGetters } from 'vuex';
import { VueLoading } from 'vue-loading-template';
import { convertMBtoKB } from '@/helpers/file';

export default {
  name: 'Fileupload',
  data() {
    return {
      url: null,
      fileError: false,
      urlName: 'ファイルを選択'
    };
  },
  props: {
    loading: Boolean,
    disabled: Boolean,
    uploaded: Function,
    initialUrl: String,
    statePath: String,
    remove: Function,
    maxSize: {
      type: Number,
      default: 30,
    },
    acceptedExtensions: {
      type: String,
      default: 'image/jpeg,image/png',
    },
    acceptedAllExtensions: {
      type: String,
      default: 'image/*',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isUploadFileSound: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ''
    },
    isBtnDeleteShown: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VueLoading,
  },
  watch: {
    initialUrl: {
      immediate: true,
      handler(val) {
        this.url = val;
        if (this.isAudioType && val) this.urlName = val;
        if (val === 'ファイルを選択') this.$refs.uploadedFile.value = null;
      }
    },
    uploadErrors(val) {
      if (val && val.length) {
        const index = val.findIndex(item => item.image === this.url);
        if (index !== -1) {
          this.deleteImage();
          this.$store.commit('common/REMOVE_FROM_UPLOAD_ERRORS', index);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      uploadErrors: 'common/uploadErrors',
    }),
    isAudioType() {
      return this.type === 'audio';
    },
    newUrlName() {
      return this.urlName;
    },
    isDefaultUrlName() {
      return this.isAudioType && this.urlName === 'ファイルを選択';
    }
  },
  methods: {
    uploadAudio() {
      this.$refs.uploadedFile.click();
    },
    async upload(event) {
      this.$message.reset();
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];
      if (this.checkFile(file)) {
        this.url = this.isUploadFileSound ? file : await this.getBase64(file);
        this.urlName = file?.name;
        this.$emit('uploaded', this.url, this.statePath);
      } else {
        this.$refs.uploadedFile.value = null;
      }
    },
    deleteImage() {
      if (this.statePath) {
        this.$emit('removed', this.statePath);
      } else {
        this.$emit('removed', this.url);
      }
      this.url = null;
      this.urlName = 'ファイルを選択';
      this.$refs.uploadedFile.value = null;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //check file type and size
    checkFile(file) {
      let result = true;
      // キャンセルしたら処理中断
      if (!file) {
        this.fileError = true;
        result = false;
      }
      // jpeg か png 関連ファイル以外は受付けない
      else if ((!this.acceptedExtensions.includes(file.type) && !this.isAudioType) ||
        (this.isAudioType && !this.acceptedExtensions.includes(file.type.substring(6)))
      ) {
        this.$message.showError('onlyImageFile');
        this.fileError = true;
        result = false;
      }
      // 上限サイズより大きければ受付けない
      else if (this.maxSize && file.size > convertMBtoKB(this.maxSize)) {
        this.$message.overMaxsize(this.maxSize, this.isAudioType)
        this.fileError = true;
        result = false;
      }
      else this.fileError = false;
      return result;
    }
  },
};
</script>

<style lang="scss" scoped>
.loading-upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
  display: flex;
}

.form-audio-input {
  display: none;
}

.btn-black {
  width: 176px;
  font-family: initial;
}

.btn-black:hover {
  transform: none;
}
</style>
