export class FileConstants {
  static CSV_MINETYPES = [
    'text/csv',
    'text/plain',
    'application/csv',
    'text/comma-separated-values',
    'application/excel',
    'application/vnd.ms-excel',
    'application/vnd.msexcel',
    'text/anytext',
    'application/octet-stream',
    'application/txt',
  ];
  static ACCEPTED_CSV_ENCODEING = 'UTF8,SJIS';
}
  